import React from 'react'
import { Link } from 'react-router-dom'

export default function Philosophy(props) {
    return (
        <div className="row philosophy g-0">
            <div className="col">
                <div className="container">
                    <div className="phil-overlay d-none d-md-block">
                        <img src="assets/img/philosophy-overlay.png" alt="" />
                    </div>
                    <div className="row g-0 phil">
                        <div className="col-12 col-md-6 phil-left">
                            <div className="phil-left-inner-top">
                                <img src={props.philLeft} alt="" className='w-100' />
                            </div>

                        </div>
                        <div className="col-12 col-md-6 phil-right">
                            <div className="phil-right-title">
                                <img src="assets/img/phil-title-bg.png" alt="" />
                                <div className="phil-right-title-text">
                                    <span>{props.philRightTitleText}</span>
                                </div>
                            </div>
                            <h2 className="heading">
                                {props.heading}
                            </h2>
                            <div className='phil-body'>
                                <p>
                                    {props.philBody}
                                </p>

                            </div>
                            <form className="d-flex phil-cta">
                                <Link to={props.philBtnLink} className="btn button-primary text-uppercase" type="submit">{props.philBtn}</Link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
