import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { SherpaContext } from '../context/notes/Sherpacontext';

export default function Navbar() {
    const { setting } = useContext(SherpaContext);
    return (
        <nav className="navbar navbar-expand-lg fixed-top bg-body-tertiary">
            <div className="container-fluid">
                <NavLink className="navbar-brand" to="/">
                    <img src={setting.website_logo_dark} className="d-inline-block align-text-top" alt='' />
                </NavLink>
                <button className="navbar-toggler shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent" style={{ height: '100%' }}>
                    <ul className="navbar-nav  mb-2 mb-lg-0">
                        <li className="nav-item"><NavLink className="nav-link" aria-current="page" to="/"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">Home</span></NavLink></li>
                        <li className="nav-item"><NavLink className="nav-link" to="/about-us"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">About Us</span></NavLink></li>
                        <li className="nav-item"><NavLink className="nav-link" to="/trekking"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">Trekking</span></NavLink></li>
                        <li className="nav-item"><NavLink className="nav-link" to="/peak-climbing"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">Peak Climbing</span></NavLink></li>
                        <li className="nav-item"><NavLink className="nav-link" to="/gallery#"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">Gallery</span></NavLink></li>
                        <li className="nav-item"><NavLink to='/contact-us' className="btn button-primary-outline text-uppercase" type="submit"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">Get In Touch</span></NavLink></li>
                    </ul>
                    {/* <form className="d-flex nav-contact-cta" role="Contact Button">
                        <NavLink to='/contact#' className="btn button-primary-outline text-uppercase" type="submit"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">Get In Touch</span></NavLink>
                    </form> */}
                </div>
            </div>
        </nav>
    )
}
