import React from "react";
import Slider from "react-slick";
import TestimonialItem from './TestimonialItem'

export default function TestimonialSlider(props) {
    var settings1 = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        swipeToSlide: true,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 992,
                settings1: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings1: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 576,
                settings1: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };
    return (
        <div className='row testimonial-slider p-3 p-md-5 g-0'>
            <div className="col mb-5">
                <Slider {...settings1}>
                    {props.testimonialsData.map((item) => (
                        <TestimonialItem key={item.name} testimonialMsg={item.content} testimonialName={item.name} testimonialOccupation={item.postion} link={item.video_link.replace("shorts", 'embed')} />
                    ))}
                </Slider>
            </div>
        </div>
    )
}

