import React from 'react'
import AboutTeamItem from './AboutTeamItem'
// import AboutPorterItem from './AboutPorterItem'

export default function AboutTeam(props) {
    return (
        <>
            <div className="bg-light px-0 ">
                <img src="/assets/img/main-inner-bottom-bg.png" alt="" className='w-100' />
                <div className="container">
                    <div className="row">
                        <div className="col-12 about-team">
                            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 g-0 py-3 py-md-5 justify-content-center">
                                <h1 className="text-uppercase text-center text-nowrap w-100">meet the team</h1>
                                {props.teamData?.map((item, index) => (
                                    <AboutTeamItem key={index} id={index} teamImg={item.img} teamPosition={item.postion} teamName={item.name} teamDesc={item.description} />
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
                <img src="/assets/img/main-inner-bottom-bg.png" alt="" className='w-100 rotate180' />
            </div>
            {/* <div className="container">
                <div className="row">
                    <div className="col-12 about-team">
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 g-0 py-3 py-md-5">
                            <h1 className="text-uppercase text-center text-nowrap w-100">meet the Porters</h1>
                            {props.teamData?.map((item, index) => (
                                <AboutPorterItem key={index} id={index} teamPImg="./assets/img/user.png" teamPosition={item.position} teamName={item.name} teamDesc={item.description} />
                            ))}
                        </div>
                    </div>  
                </div>
            </div> */}

        </>
    )
}