import { Parser } from 'html-to-react'
import React from 'react'

export default function TestimonialItem(props) {
    return (
        <div className="col p-3 px-md-4">
            <div className='shadow rounded-3 bg-white p-3 '>
                {/* <p className='textcolor-5 d-flex border-bottom'>
                    {Array.from({ length: props.rate }, (_, i) => <i key={i} className="bi bi-star-fill"></i>)}
                </p> */}
                {props.testimonialMsg != null ? <span>{Parser().parse(props.testimonialMsg)}</span> : ''}
                {props.testimonialMsg === null ? <p><iframe src={`${props.link}`} width="100%" height="200" allowFullscreen="allowfullscreen"></iframe></p> : ''}

            </div>
            <div className='p-3 text-center'>
                <h4>{props.testimonialName}</h4>
                <h6>{props.testimonialOccupation}</h6>
            </div>
        </div >
    )
}
