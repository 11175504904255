import React, { Component } from "react";
import Slider from "react-slick";

export default class MainInnerPageLeft extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    render() {
        return (

            <div>
                <div className="main-inner-page-left-img-top">
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}

                    >
                        {this.props.images?.map((item, index) => (
                            <div key={index}>
                                <img src={item} alt="" className="w-100" style={{ height: '500px', objectFit: 'cover' }} />
                            </div>
                        ))}
                    </Slider>
                </div>
                <div className="main-inner-page-left-img-below py-3">
                    <Slider
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        slidesToShow={4}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        infinite={true}
                        centerMode={true}
                        centerPadding='10px'
                    >
                        {this.props.images?.map((item, index) => (
                            <div key={index}>
                                <img src={item} alt="" className="w-100 px-2" style={{ height: '100px', objectFit: 'cover' }} />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div >
        );
    }
}

// import { useEffect, useState, useRef } from "react";
// import Slider from "react-slick";

// export default function MainInnerPageLeft(props) {
//     const [nav1, setNav1] = useState();
//     const [nav2, setNav2] = useState();
//     const slider1 = useRef();
//     const slider2 = useRef();
//     return (
//         <div>
//             {/* <div className="main-inner-page-left-img-top">
//                 <img src="/assets/img/langtang.jpg" alt="" />
//             </div>
//             <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4 main-inner-page-left-img-below py-3">
//                 {props.images?.map((item, index) => (
//                     <div className="col py-3" key={index}>
//                         <img src={item} alt="" />
//                     </div>
//                 ))}
//             </div> */}
//             <Slider className="mainSlider" asNavFor={nav2} ref={slider1 => setNav2(slider1)} >
//                 {props.images?.map((item, index) => (
//                     <img src={item} alt="" key={index} style={{ height: ' 400px' }} />
//                 ))}
//             </Slider>

//             <Slider asNavFor={nav1} ref={slider2 => setNav1(slider2)} slidesToShow={3} swipeToSlide={true} focusOnSelect={true}>
//                 {props.images?.map((item, index) => (
//                     <div className="col py-3" key={index}>
//                         <img src={item} alt="" />
//                     </div>
//                 ))}
//             </Slider>
//         </div>
//     )
// }
