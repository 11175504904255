import React from 'react'

export default function Reviews(props) {
    return (
        <div>
            <div className='py-3'>
                <p className='textcolor-5 d-flex'>
                    {Array.from({ length: props.rating }, (_, i) => <i key={i} className="bi bi-star-fill"></i>)}

                </p>
                <p>{props.content}</p>
                <p className='text-end'> {props.name}</p>
            </div>
        </div>
    )
}
