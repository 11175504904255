import { Parser } from 'html-to-react'
import React from 'react'
import { Link } from 'react-router-dom'


export default function Explore(props) {
    return (
        <div className="explore position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-0 col-md-0 col-xl-2 "></div>
                    <div className="col-12 col-md-12 col-xl-6 d-flex align-items-center">
                        <div>
                            <img src="assets/img/explore-mountain.png" alt="" />
                        </div>
                        <span className='text-capitalize px-xl-4 px-2 px-md-3 small my-1 d-md-none'><span>{props.exploreTop}</span><br></br> {Parser().parse(props.exploreBottom)} </span>
                        <span className='text-capitalize px-xl-4 px-2 px-md-3 small my-1 d-none d-md-block'><span className='text-nowrap'>{props.exploreTop}</span><br></br> {Parser().parse(props.exploreBottom)} </span>
                    </div>
                    <div className="col-12 col-md-12 col-xl-2 d-flex align-items-center justify-content-center">
                        <Link to={props.exploreLink} className="btn btn-outline-secondary text-uppercase">explore now</Link>
                    </div>
                    <div className="col-0 col-md-1 col-xl-2"></div>
                </div>
            </div>
            {/* <img src="assets/img/birds.png" alt="" className='w-100' /> */}
            {/* <div className="position-absolute bottom-0 start-0">
                <img src="assets/img/testimonials-below.png" alt="" className='w-100' />
            </div> */}
        </div>
    )
}
