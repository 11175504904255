import React from 'react'
import { Link } from 'react-router-dom'

export default function Hero(props) {
    return (
        <>
            {/* {console.log(props.heroBg)} */}
            <div className='row g-0 hero position-relative ' style={{ backgroundImage: `url(${props.heroBg})` }}>
                <div className="col-12 text-center hero-text">
                    <div className="row g-0 h-100 justify-content-center align-items-center">
                        <div className="col">
                            <p className='text-uppercase hero-title-top'>{props.heroTitleTop}</p>
                            <p className="hero-title text-uppercase">
                                <span className='text-nowrap'>{props.heroTitleLeft} </span> <br></br> {props.heroTitleRight}
                            </p>
                            <p className='mt-3 mt-md-4 mt-xl-5 mb-0'><Link to={props.heroExploreLink} className='btn btn-light rounded-0 text-uppercase hero-cta'> {props.heroBtnText}</Link></p>
                        </div>
                    </div>

                </div>
                <div className="position-absolute bottom-0">
                    <img src="assets/img/hero-bottom.png" alt="" className='w-100' />
                </div>
            </div >
        </>
    )
}
