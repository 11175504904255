import React, { useState } from 'react'
import { axiosBase, recaptchaSiteKey } from '../Rootlayout'
import Reviews from './Reviews'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from "react-google-recaptcha";




export default function ClientReview(props) {
    const [name, createName] = useState('')
    const [rating, setRating] = useState(3)
    const [hover, setHover] = useState('')
    const [content, createContent] = useState('')
    const [country, setCountry] = useState('')
    const [update, setUpdate] = useState(null)
    const [verified, setVerified] = useState('');
    // const [firstIndex, setFirstIndex] = useState(0)
    // const [buttonText, setButtonText] = useState('Show More')
    function onChange(value) {
        // console.log("Captcha value:", value);
        setVerified(true);
    }

    let count = 0;
    const onSubmit = async (e) => {
        e.preventDefault()
        const post = {
            name: name,
            rating: rating,
            content: content,
            country: country,
        }
        try {
            const res = await axiosBase.post(`reviews/${props.id}`, post);
            if (res.data.status) {
                // setUpdate(post);
                setUpdate(res.data.data);
            }
            toast.success(res.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "dark",
            });
            // const notify = () => toast(res.data.message);
            // alert(res.data.message);
            // window.location.reload(true);


        } catch (e) {
            toast.error(e, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        }
        e.target.reset();
        setRating(3);
    }
    props.reviews?.map((i) => {
        return count++;
    })

    return (
        <div className="container">
            <div className="row client-review">
                <div className="col-12 col-md-8 px-0">
                    <h6>Client Reviews</h6>
                    {update == null ? '' : <Reviews rating={update.rating} name={`--${update.name}`} content={update.content} />}

                    {props.reviews?.slice(0, 3).map((item, index) => (
                        <Reviews key={index} rating={item.rating} name={`--${item.name}`} content={item.content} />
                    ))}
                    <div className="collapse" id="collapseMore">
                        {props.reviews?.slice(3, count).map((item, index) => (
                            <Reviews key={index} rating={item.rating} name={`--${item.name}`} content={item.content} />
                        ))}
                    </div>
                    <button className={`btn button-primary rounded-2 w-100 my-3 ${count > 3 ? '' : 'd-none'}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseMore" aria-expanded="false" aria-controls="collapseMore">Show More</button>
                    <button className="btn button-primary-outline my-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">Write a Review</button>
                    <div className="collapse" id="collapseExample">
                        <form onSubmit={onSubmit} className='py-3'>
                            <div className="mb-3">
                                <label htmlFor="labeltext" className="form-label">Comments</label>
                                <textarea className="form-control" placeholder="Leave a comment here" id="labeltext" style={{ height: "100px" }} required onChange={(event) => { createContent(event.target.value) }} ></textarea>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="mb-3">
                                    <label htmlFor="labelname" className="form-label">Name</label>
                                    <input type="text" className="form-control" id='labelname' name='name' required onChange={(event) => { createName(event.target.value) }} />
                                </div>
                                <div className="mb-3">
                                    <select className="form-select" aria-label="Default select example" required onChange={(event) => { setCountry(event.target.value) }} >
                                        <option defaultValue>Choose Country</option>
                                        {props.countries.countries?.map((item, i) => (
                                            <option key={i} value={`${item.country_name}`}>{item.country_name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Rating</label>
                                    <p className='rating'>
                                        {[...Array(5)].map((star, i) => {
                                            const currentRating = i + 1;
                                            return <label key={i}>
                                                <input type='radio' name='rating' value={currentRating} onClick={() => setRating(currentRating)} />
                                                <i className={`bi bi-star-fill ${currentRating <= (hover || rating) ? 'text-warning' : ''}`} onMouseEnter={() => setHover(currentRating)} onMouseLeave={() => setHover('')}></i>
                                            </label>

                                        })}
                                    </p>
                                </div>

                            </div>
                            <div >
                                <ReCAPTCHA
                                    sitekey={recaptchaSiteKey}
                                    onChange={onChange}
                                />
                                <button type="submit" className="btn button-primary mt-3" disabled={!verified}>Submit</button>
                                <ToastContainer />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    )
}
